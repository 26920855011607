<template>
	<div class="leading-relaxed tracking-wide">
		<h2 class="text-black font-bold text-xl my-5">مبادئ العمل</h2>
		<p class=" font-medium">تتعد شركة زين بتوفير خدماتنا طبقاً للشروط العامة لهذه الخدمات الموضحة اذناه:-</p>
		<ul class="w-full md:w-auto my-8">
			<li class="flex items-center my-2 " v-for="(item, index) in data" :key="index">
				<span class="block rounded-md bg-primary h-4 w-4 mx-2"></span>
				<span class="w-11/12">{{ item }}</span>
			</li>
		</ul>
	</div>
</template>
<script>
export default {
	data() {
		return {
			data: [
				'توفير موظفي التموبن / سفرجة ونظافة (رجال او نساء) بدوام منتظم صباحاً أو مساء حسب ما يتم االتفاق عليه وبناء على متطلبات المؤسسة.',
				'توفير موظفي سفرجة ونظافة بدوام ساعات للعمل أثناء الفعاليات أو أيام العطل الرسمية والإجازات وذلك بناء على احتياج المؤسسة وعند الطلب.',
				'تلتزم الشركة بتوفير الشهادات الصحية والمستندات المطلوبة للموظفين.',
				'أن تتراوح أعمار الموظفين بين 22 سنة-35 سنة، وأن يكونوا طبقاً للمواصفات الصحية والتعليمة المتفق عليها.',
				'تلتزم الشركة بتقديم السيرة الذاتية لموظفي السفرجة و نظافة لاعتمادهم بشكل رسمي من قبل الزبون (المؤسسة- الجهة المتعاقدة) قبل مباشرة العمل.',
				'تلتزم الشركة بتوفير صور شخصية و صور عن بطاقات الهوية الشخصية لجميع الموظفين في القائمتين الدائمة والمؤقتة.',
				'تلتزم الشركة بأوقات العمل التي يحددها الزبون.',
				'تلتزم الشركة بتدريب موظفي السفرجة والنظافة على المهام المطلوب منهم متابعتها في مبنى الزبون، بحيث يكونوا جميعهم على دراية تامة بتفاصيل المبنى واحتياجاته والمهام المناطة بهم لضمان نظافته بالشكل المطلوب.',
				'تلتزم الشركة باستعمال المواد المسموح بها والمحددة من الزبون كمستلزمات العناية و النظافة الخضراء”المعتمدة ضمن سياسة إدارة النظافة في مبنى الزبون',
				'تلتزم الشركة بتوفير جميع المعدات واللوازم والماكينات التي سيتم استخدامها في عمليات التنظيف في المؤسسة والمحددة مواصفاتها وأعدادها ( على أن يتم تقديم المواصفات الفنية لكل منها لاعتمادها من قبل الزبون قبل البدء باستعمالها.',
				'سيكون لجميع موظفي السفرجة والنظافة المعتمدين أن يكونوا مجهزين بزي موحد خاص بهم كامال يظهر اسم العامل و يكون الزي مطابق لشروط السلامة العامة، و ان يكونوا مجهزين بجميع المعدات اللازمة للقيام بمهامهم.',
				'للتواصل معه من قبل طاقم الزبون بشكل دائم، ويحق لطاقم الزبون التواصل مع موظفي السفرجة والنظافة واعطائهم بشكل مباشر التعليمات اللازمة لضمان تنفيذ المهام المطلوبة منهم بالشكل الصحيح وبالسرعة الممكنة. الزبون بشكل دائم، ويحق لطاقم الزبون التواصل مع موظفي السفرجة والنظافة واعطائهم',
				'يلتزم موظفي السفرجة و النظافة بإبلاغ الزبون مباشرة عن أية أضرار قد تلحق بالمرافق والمحتويات أثناء قيامهم بالمهام الموكلة إليهم.',
				'يحق للزبون زيادة أو تخفيض عدد موظفي السفرجة والنظافة حسب ما تراه مناسباً للاحتياجات العمل المتفق عليه.',
			],
		};
	},
};
</script>
